import { useEffect, useState } from 'react';
import { MobileLogSummaryInterface } from '../../../service/mobile-log/mobile-log-summary.interface';
import { MobilLogService } from '../../../service/mobile-log/mobile-log.service';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateInterface } from '../../../store/state-interfaces/root.state-interface';
import * as snackbarActions from '../../../store/actions/snackbar.actions';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import { MobileLogStatus } from '../../../service/mobile-log/mobile-log-status.enum';
import ReportCard from './ReportCard';
import { Chip } from '@mui/material';

function BugReport() {
  const [mobileLogSummaries, setMobileLogSummaries] = useState<MobileLogSummaryInterface[]>([]);
  const [displayedType, setDisplayedType] = useState<string>('all');
  const [loading, setLoading] = useState<boolean>(true);
  const mobileLogService = new MobilLogService();
  const authentication = useSelector((state: RootStateInterface) => state.authentication);
  const dispatch = useDispatch();

  const getAllMobileLogSummaries = async (feedback: boolean = true) => {
    if (!authentication.authentication?.access_token) return;
    setLoading(true);

    try {
      const data = await mobileLogService.getAll(authentication.authentication.access_token);

      setMobileLogSummaries(data);
      setLoading(false);

      feedback && dispatch(snackbarActions.setSuccessMessage('Fehlerberichte erfolgreich geladen') as any);
    } catch (error) {
      setLoading(false);
      dispatch(snackbarActions.setSuccessMessage('Fehlerberichte konnten nicht geladen werden') as any);
    }
  }

  const filterMobileLogSummaries = (status: string) => {
    setDisplayedType(status);
  }

  useEffect(() => {
    getAllMobileLogSummaries();
  }, []);

  return (
    <DocumentTitleContainer title="Fehlerberichte">
      <div className="bug-report">
        <h1>Fehlerberichte</h1>
        <Chip label="Alle" variant={displayedType === 'all' ? 'filled' : 'outlined'} color="primary" onClick={() => filterMobileLogSummaries('all')} />

        {Object.values(MobileLogStatus).map((status: string) => (
          <Chip key={status} label={status} variant={displayedType === status ? 'filled' : 'outlined'} color="primary" onClick={() => filterMobileLogSummaries(status)} />
        ))}

        {mobileLogSummaries && mobileLogSummaries.map((mobileLogSummary: MobileLogSummaryInterface) => (
          displayedType === 'all' || mobileLogSummary.status === displayedType ? (
            <ReportCard key={mobileLogSummary.id} mobileLogSummary={mobileLogSummary} getAllMobileLogSummaries={getAllMobileLogSummaries} />
          ) : null
        ))}

      </div>
    </DocumentTitleContainer>
  )
}

export default BugReport;