import { Navigate } from 'react-router-dom';
import AuthenticationLayout from '../components/layout/AuthenticationLayout/AuthenticationLayout';
import DashboardLayout from '../components/layout/DashboardLayout/DashboardLayout';
import PageNotFound from '../components/pages/PageNotFound/PageNotFound';
import Login from '../components/pages/Login/Login';
import { routesRessourcesFlat } from './routesRessources';
import PrivacyPolicy from '../components/pages/PrivacyPolicy/PrivacyPolicy';

const routes = (isLoggedIn: boolean, role: string) => {
  const filteredRoutes = (
    role ? (
      routesRessourcesFlat.filter(route => route.roles && route.roles.includes(role))
    ) : (
      routesRessourcesFlat).map(route => ({ path: route.path, element: route.element })
      )
  );

  const dashboardRoutes = [
    ...filteredRoutes,
    { path: '*', element: <Navigate to="/home" replace={true} /> },
    { path: '/', element: <Navigate to="/home" replace={true} /> },
  ];

  const authenticationRoutes = [
    { path: 'login', element: <Login /> },
    { path: '*', element: <Navigate to="/login" replace={true} /> },
    { path: '/', element: <Navigate to="/login" replace={true} /> },
  ];

  return [
    {
      path: '/',
      element: !isLoggedIn ? <AuthenticationLayout /> : <DashboardLayout />,
      children: !isLoggedIn ? authenticationRoutes : dashboardRoutes,
    },
    {
      path: '/privacypolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '*', element: <PageNotFound />
    },
  ];
};



export default routes;